import { toast } from '@coopbetala/coop-digital-react-ui'
import {
  createAsyncThunk, createEntityAdapter, createSelector, createSlice
} from '@reduxjs/toolkit'

import { RootState } from '../app/store'
import { http, thunkHandler } from '../Infrastructure/Network/api'
import { handleErrorToast } from '../Infrastructure/Network/handleError'
import { IErrorResponse, RequestStatus } from '../Infrastructure/Network/types'
import { formatStringInBlocks } from '../utilities/formatters'
import { IGiftCard, IGiftCardResponse, IResendGiftCard } from './IGiftCard'
import { fetchCheckoutDetailsForPurchase, selectCheckoutById } from '../Checkout/Checkout.slice'

interface RequestState {
  status: RequestStatus
  error?: IErrorResponse
  message?: string
}

interface GiftCardState extends RequestState {
  inactivate: RequestState
  resend: RequestState
  fetchGiftCards: RequestState
}

const giftCardsAdapter = createEntityAdapter<IGiftCard>({
  selectId: (giftCard) => giftCard.giftCardNumber,
})

const initialState = giftCardsAdapter.getInitialState<GiftCardState>({
  inactivate: {
    status: 'idle',
  },
  resend: {
    status: 'idle',
  },
  fetchGiftCards: {
    status: 'idle',
  },
  status: 'idle',
})

export const fetchGiftCards = createAsyncThunk(
  'giftCards/fetchAll',
  async (
    {
      purchaseId,
      checkoutId,
      size,
      page,
    }: { purchaseId: string; checkoutId: string, size?: number; page?: number },
    thunkAPI
  ) => {
    const params = {
      size,
      page,
    }

    const response: IGiftCardResponse = await thunkHandler(
      http.get(`/v1/admin/purchases/${purchaseId}`, { params }),
      thunkAPI
    )

    response.giftcards.forEach((giftCard) => {
      // Set checkoutId to the input parameter so that we are able to lookup
      // the Checkout (receipt) for a giftCard.
      giftCard.checkoutId = checkoutId
    })

    return response
  }
)

export interface FetchGiftCardBody {
  giftCardNumber: string
}

export const fetchGiftCard = createAsyncThunk(
  'giftCards/fetch',
  async (cardNumber: string, thunkAPI) => {
    const response: IGiftCard = await thunkHandler(
      http.post<FetchGiftCardBody>(`/v2/visa`, { giftCardNumber: cardNumber }),
      thunkAPI
    )
    // Fetch checkout details using purchaseId
    if (response.purchaseId) {
      try {
        const checkoutResponse = await thunkAPI.dispatch(
          fetchCheckoutDetailsForPurchase(response.purchaseId)
        ).unwrap()

        // Add checkoutId to the gift card
        response.checkoutId = checkoutResponse.id
      } catch (error: any) {
        // There is not always a checkout for a giftCard so CHECKOUT_NOT_FOUND
        // is expected.
        if (error.errorCode !== 'CHECKOUT_NOT_FOUND') {
          console.error('Failed to fetch checkout details for purchase', error)
        }
        // Set checkout id to null in all cases even when there is an error.
        response.checkoutId = null
      }
    }
    return response
  }
)

export interface InactivateGiftCardBody {
  giftCardNumber: string
  cardType: string
  idType: string
}

export const inactivateGiftCard = createAsyncThunk(
  'giftCards/inactivate',
  async (cardNumber: string, thunkAPI) => {
    const response: any = await thunkHandler(
      http.post<InactivateGiftCardBody>(`/v1/admin/inactivate`, {
        cardType: 'DIGITAL',
        idType: 'CARDNUMBER',
        giftCardNumber: cardNumber,
      }),
      thunkAPI
    )

    return response
  }
)

export const resendGiftCard = createAsyncThunk(
  'giftCards/resend',
  async (giftCard: IResendGiftCard, thunkAPI) => {
    const response: any = await thunkHandler(
      http.post(`/v1/admin/resend-giftcard`, giftCard),
      thunkAPI
    )

    return response
  }
)

const giftCardsSlice = createSlice({
  name: 'giftCards',
  initialState,
  reducers: {
    removeAllGiftCards: giftCardsAdapter.removeAll,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGiftCards.pending, (state, _action) => {
        state.fetchGiftCards.status = 'pending'
      })
      .addCase(fetchGiftCards.fulfilled, (state, action) => {
        state.fetchGiftCards.status = 'succeeded'
        giftCardsAdapter.addMany(state, action.payload.giftcards)
      })
      .addCase(fetchGiftCards.rejected, (state, action) => {
        const error = action.payload as IErrorResponse
        state.fetchGiftCards.status = 'failed'
        state.error = error
      })

    builder
      .addCase(fetchGiftCard.pending, (state, action) => {
        state.status = 'pending'
      })
      .addCase(fetchGiftCard.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.error = undefined
        giftCardsAdapter.upsertOne(state, action.payload)
      })
      .addCase(fetchGiftCard.rejected, (state, action) => {
        const error = action.payload as IErrorResponse
        state.status = 'failed'
        state.error = error
        handleErrorToast(error)
      })

    builder
      .addCase(inactivateGiftCard.pending, (state, action) => {
        state.inactivate.status = 'pending'
      })
      .addCase(inactivateGiftCard.fulfilled, (state, action) => {
        state.inactivate.status = 'succeeded'
        state.inactivate.error = undefined
        state.inactivate.message = `Presentkortet med nummer (${formatStringInBlocks(
          action.meta.arg
        )}) har spärrats`
        giftCardsAdapter.updateOne(state, {
          id: action.meta.arg,
          changes: {
            status: 'INACTIVE',
          },
        })
        toast.success(state.inactivate.message)
      })
      .addCase(inactivateGiftCard.rejected, (state, action) => {
        const error = action.payload as IErrorResponse
        state.inactivate.status = 'failed'
        state.inactivate.error = error
        handleErrorToast(error)
      })

    builder
      .addCase(resendGiftCard.pending, (state, action) => {
        state.resend.status = 'pending'
      })
      .addCase(resendGiftCard.fulfilled, (state, action) => {
        const { cardNumber, phone, email } = action.meta.arg
        state.resend.status = 'succeeded'
        state.resend.message = `Presentkortet (${formatStringInBlocks(
          cardNumber
        )}) har skickats till ${phone || email}`
        state.resend.error = undefined
        giftCardsAdapter.updateOne(state, {
          id: cardNumber,
          changes: { recipientEmail: email, recipientPhone: phone },
        })
        toast.success(state.resend.message)
      })
      .addCase(resendGiftCard.rejected, (state, action) => {
        const error = action.payload as IErrorResponse
        state.resend.status = 'failed'
        state.resend.error = error
        handleErrorToast(error)
      })
  },
})

export default giftCardsSlice.reducer

export const { removeAllGiftCards } = giftCardsSlice.actions

export const selectGiftCardsByCheckoutId = (checkoutId: string) =>
  createSelector(
    [
      selectAllGiftCards,
      (state: RootState) => selectCheckoutById(state, checkoutId),
    ],
    (giftCards, checkout) => {
      const purchaseIds =
        checkout?.purchases?.map(
          (purchase) => purchase.digitalGiftCard.purchaseId
        ) || []

      return giftCards.filter((giftCard) =>
        purchaseIds?.includes(giftCard.purchaseId)
      )
    }
  )

export const {
  selectAll: selectAllGiftCards,
  selectById: selectGiftCardById,
  selectIds: selectGiftCardsIds,
} = giftCardsAdapter.getSelectors((state: RootState) => state.giftCards)
